<template>
  <div>
    <div v-show="shopeeToken==null">
      身分未經授權<br/>請勿將此網址加入我的最愛，請至<a href="https://www.invoicego.tw/login/?next=/">發票通管理平台</a>進行身分授權或請洽管理員
      <br/>若登入再次失敗請將瀏覽器上方網址複製給管理員回報，我們將盡快協助處理問題
      <br/>
      {{this.$route.query.code !=undefined ? ('(error code:'+this.$route.query.code +')'): ''}}
    </div>
    <b-tabs v-show="shopeeToken!=null">
      <b-tab title="資料匯入" v-show="shopeeToken!=null">
          <b-card>
            <b-overlay :show="show" rounded="sm">
              <b-row>
                <b-col :cols="2">
                  <img :src="sppngSrc" alt="Logo" style="height:40px;"/>
                </b-col>
                <b-col :cols="7">
                  <b-form-group label="開啟Excel密碼:" label-for="file-small" label-cols-sm="4">
                    <b-form-input v-model="excelPwd"></b-form-input>
                  </b-form-group>
                  <b-form-group label="蝦皮訂單上傳區:" label-for="file-small" label-cols-sm="4">
                    <b-form-file browse-text="選擇訂單Excel檔" id="orderInv" v-model="fileSpOrder" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" placeholder="檔案拖移此處" drop-placeholder="Drop file here..." />
                  </b-form-group>
                </b-col>
                <b-col :cols="3">
                  <b-button variant="success" @click="uploadShOrderXlsx()">上傳</b-button>
                  <div v-show="false">
                    <input type="text" v-model="jsonFileName">
                    <b-button @click="startLogForDev()">重新紀錄</b-button>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col :cols="2"></b-col>
                <b-col :cols="3">
                  <b-form-checkbox checked :disabled="true" value="Y" v-model="paperPrintCheckbox" v-show="paperPrint == 'Y'">
                    已啟用紙本發票
                  </b-form-checkbox>
                  <b-form-checkbox checked :disabled="true" value="N" v-model="paperPrintCheckbox" v-show="paperPrint == 'N'">
                    未啟用紙本發票
                  </b-form-checkbox>
                </b-col>
                <b-col :cols="4">
                  <b-alert variant="warning" show v-show="paperPrint == 'N'">
                      <div class="alert-body">
                        <span>如需使用紙本發票列印功能請洽管理員</span>
                      </div>
                  </b-alert>
                </b-col>
              </b-row>
            </b-overlay>
          </b-card>
          <b-row>
            <b-col cols="12">
              <b-card title="最近上傳5筆">
                <!-- <table class="history-table">
                  <tr class="history-table"><td class="history-table">檔案名稱</td><td class="history-table">上傳時間</td><td class="history-table">發票數量</td><td class="history-table">開立完成時間</td></tr>
                  <tr v-for=" (i,k) in xlsHistory" :key="k" class="history-table">
                    <td class="history-table">
                      <a href="#" target="_self" style="font-size:12px;" 
                        @click="downloadHistory(i.rowId,i.fileName)" 
                        >{{i.fileName}}</a> 
                    </td>
                    <td class="history-table">{{i.uploadDateDisplay}}</td>
                    <td class="history-table" style="text-align:right;padding-right:10px;">{{i.invQty}}</td>
                    <td class="history-table">{{i.finishIssueDateDisplay}}</td>
                  </tr>
                </table> -->
                <b-table responsive="sm" :items="xlsHistory" :fields="uploadHistoryFields">
                  <template #cell(invQty)="data">
                      <div class="text-right" style="margin-right:10px;">{{data.item.invQty}}</div>
                  </template>
                  <template #cell(price)="data">
                      <div class="text-right" style="margin-right:10px;">{{data.item.price}}</div>
                  </template>
                  <template #cell(tax)="data">
                      <div class="text-right" style="margin-right:10px;">{{data.item.tax}}</div>
                  </template>
                  <template #cell(fileName)="data">
                    <a href="#" target="_self" style="font-size:12px;" 
                        @click="downloadHistory(data.item.rowId,data.item.fileName)" 
                        >{{data.item.fileName}}</a> 
                    <!-- <div>{{data.item.orderDate.create}}</div> -->
                  </template>
                </b-table>
              </b-card>
            </b-col>
            <!-- <b-col cols="3">
              <b-card title="上傳結果">
                <div>開立發票:<a href="#" target="_self" style="font-size:26px;" class="ml-1">{{tab3UploadCreate}}</a>  張</div>
                <div>新增訂單:<a href="#" target="_self" style="font-size:26px;" class="ml-1">{{tab3UploadCreate}}</a>  張</div>
                <div>已存在訂單:<a href="#" target="_self" style="font-size:26px;" class="ml-1">{{tab3UploadExistOrderQty}}</a>  張</div>
              </b-card>
            </b-col>
            <b-col cols="3">
              <b-card title="金額計算">
                <div>本次新增銷售額:<a href="#" target="_self" style="font-size:26px;" class="ml-1">{{tab3UploadPrice}}</a> 元</div>
                <div>本次新增稅額:<a href="#" target="_self" style="font-size:26px;" class="ml-1">{{tab3UploadTax}}</a>  元</div>
                <div>本次折讓金額:<a href="#" target="_self" style="font-size:26px;" class="ml-1">{{tab3UploadReturnPrice}}</a>  元</div>
              </b-card>
            </b-col> -->
          </b-row>

          <!-- 停用 -->
          <b-card v-show="false">
            <b-overlay :show="show" rounded="sm" >
              <b-row>

                <b-col>
                  <!-- https://www.itread01.com/content/1546451657.html -->
                  <b-form-group label="蝦皮對帳單傳區:" label-for="file-small" label-cols-sm="4">
                    <b-form-file id="orderPur" v-model="fileSpIncome" accept="application/vnd.ms-excel" placeholder="檔案拖移此處" drop-placeholder="Drop file here..." />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-button @click="uploadShIncomeXlsx()">上傳蝦皮對帳單</b-button>
                </b-col>
              </b-row>
          </b-overlay>
          </b-card>
          
          <!-- 銀行往來明細 -->
          <b-card v-show="false">
            <b-form-group label="銀行往來明細:" label-for="file-small" label-cols-sm="2">
              <b-form-file id="bankDetail" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" placeholder="檔案拖移此處" drop-placeholder="Drop file here..." />
            </b-form-group>
          </b-card>
      </b-tab>
      <!-- <b-tab title="訂單資料" v-show="shopeeToken!=null">
        <b-row>
          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col cols="4">
                  <b-form-group label-cols="3" content-cols="9" label="訂單編號" label-for="qGetDate">
                    <b-form-input id="qGetDate" v-model="q.qOrderNo"/>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-form-group label-cols="3" content-cols="9" label="發票號碼" label-for="qGetDate">
                    <b-form-input id="qGetDate" v-model="q.qInvNo"/>
                  </b-form-group>
                </b-col>
                <b-col cols="4">
                  <b-button type="submit" class="mr-1" variant="primary" @click="queryInv(1)">查詢</b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <b-col cols="9">
            <b-table responsive="sm" :items="orderTableData" :fields="fields">
            <template #cell(orderDate)="data">
              <div>{{data.item.orderDate.create}}</div>
              <div>{{data.item.orderDate.finish}}</div>
            </template>
            <template #cell(editId)="data">
              <b-button @click="queryInvDetail(data.item)" size="sm">查</b-button>
            </template>
            </b-table>
          </b-col>
          <b-col cols="3">
            <b-card>
              <b-overlay rounded="sm">
                訂單明細
                <table>
                  <tr><td>序號</td><td>名稱</td><td>數量</td><td>金額</td></tr>
                  <tr v-for="i in invDetails" :key="i.rowId"><td>{{i.seq}}</td><td>{{i.prodName}}</td><td style="text-align:right">{{i.qty}}</td><td style="text-align:right">{{i.amt}}</td></tr>
                </table>
                <b-button type="submit" class="mr-1" variant="primary" @click="clickIssueInv" v-show="canIssueInv">開立發票</b-button>
              </b-overlay>
            </b-card>
          </b-col>
          <b-col cols="6">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="pageSize" 
              @page-click="pageClick" class="mb-1"  :limit="10"/>
          </b-col>
        </b-row>
      </b-tab> -->
    </b-tabs>
    <b-modal id="modal-update" ok-title="確定" cancel-title="取消" title="編輯視窗" :no-close-on-backdrop="true" size="lg" @ok="confirmUpdate()">
      <b-row>
        <b-col cols="3">
          <b-form-group label="憑證日期" label-for="docDate">
            <b-form-input id="docDate" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="交易日期" label-for="getDate">
            <b-form-input id="getDate" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="資金帳戶" label-for="accountingId">
            <b-form-input id="accountingId" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="交易對象" label-for="targetDisName">
            <b-form-input id="targetDisName" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="會計科目" label-for="accCode">
            <b-form-input id="accCode" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="稅別" label-for="taxType">
            <v-select
              id="taxType"
              v-model="taxType"
              :options="taxTypeOptions"
              :selectable="option => ! option.value.includes('select_value')"
              label="text"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="交易金額" label-for="amt">
            <b-form-input id="amt" v-model="name"/>
          </b-form-group>
        </b-col>
        <b-row class="clearfix"></b-row>
        <b-col cols="12">
          <b-form-group label="備註" label-for="note">
            <b-form-input id="note" v-model="name"/>
          </b-form-group>
        </b-col>

      </b-row>
    </b-modal>
    <b-modal
      id="modal-delete"
      ok-title="確定"
      cancel-title="取消"
      centered
      title="刪除視窗"
      :no-close-on-backdrop="true"
      @ok="confirmDelete()"
    >
      <label for="" class="label-out">確定要刪除嗎嗎?</label> 
    </b-modal>
  </div>
</template>

<script>

import {
  BCard, BCardText, BCol, BRow, BFormGroup, BFormInput, BButton,BTab,BTabs,BFormFile,BOverlay,BTable,BPagination,BFormCheckbox,BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCol,
    BTab,
    BTabs,
    BRow,
    BCard,
    BCardText,
    BFormGroup,
    BFormInput,
    BButton,
    BOverlay,BTable,BPagination,BFormCheckbox,BAlert,
    vSelect,
    BFormFile,
  },
  data() {
    return {
      paperPrint: 'N',
      paperPrintCheckbox: ['Y'],
      shopeeToken: null,
      currentPage :1,
      pageSize :12,
      rows: 0,
      xlsHistory: [],
      show: false,
      tableBasic: [],
      name: '',
      taxType: null,
      taxTypeOptions: [{value: '1', text: '應稅'}],
      q: {
        qInvNo: '',
        qOrderNo: '',
        qCusNo:'',
      },
      fileSpInv: null,
      fileSpOrder: null,
      excelPwd:'',
      fileSpIncome: null,
      token: null,
      cusNo: '83267911',
      tab3UploadPrice: 0,
      tab3UploadTax: 0,
      tab3UploadCreate: 0,
      tab3UploadExistInvoiceQty:0,
      tab3UploadReturnInvoiceQty:0,
      tab3UploadReturnPrice: 0,
      tab3UploadExistOrderQty: 0,
      user: {
        cusNo: '',
        userId: '',
      },
      selectRowSpOrder:null,
      orderTableData:[],
      invDetails: [],
      uploadHistoryFields: [
        {key: 'fileName', label: '檔名'},
        {key: 'uploadDateDisplay', label: '上傳日期'},
        {key: 'invQty', label: '發票開立張數'},
        // {key: 'price', label: '發票銷售額'},
        // {key: 'tax', label: '發票稅額'},
        {key: 'finishIssueDateDisplay', label: '完成開立日期'},
      ],
      fields: [
        {key: 'orderNo', label: '單號'},
        {key: 'flow', label: '狀態'},
        {key: 'invNo', label: '發票號碼'},
        {key: 'invCreateDate', label: '發票開立日期'},
        {key: 'tax', label: '稅額'},
        {key: 'totalPrice', label: '金額'},
        {key: 'orderDate', label: '建立/完成日期'},
        {key: 'uploadDate', label: '上傳日期'},
        {key: 'editId', label: '明細'},
      ],
      jsonFileName:'',
      sppngSrc:process.env.BASE_URL + '/ordertype/sp.png',
    }
  },
  created() {
    let userData = { ability: [
      {action:'shopee',subject:'Auth'},
      // {action:'basic',subject:'Auth'},
    ]}
    this.$ability.update(userData.ability)

    let b64 = null

    // // local一定要拿掉 
    // let a = ((83267911 + 20241007) / 7) 
    // let o64 = window.btoa('83267911&invoicegoshopee&' + a.toFixed(0) + '&1')
    // console.log(o64)
    // b64 = window.atob(o64)
    // end local 一定要拿掉

    if(this.$route.query.code!=undefined){
      b64 = window.atob(this.$route.query.code)
      // console.log(b64)
    } else if(this.$route.query.sucode!=undefined){
      b64 = this.$route.query.sucode
    }
    
    if(b64 == null && sessionStorage.getItem('invGoToken')!=null){
      b64 = sessionStorage.getItem('invGoToken')
    }

    if(this.$route.query.paperPrint!=undefined){
      this.paperPrint = this.$route.query.paperPrint
    }

    let user = {
      username:'統編未授權  帳號未授權',
      accountId: '未授權',
      cusNo: '未授權',
      role:'',
    }
    if(b64== null){
      this.$toast({ component: ToastificationContent,
        props: {title: '驗證錯誤', variant: 'danger', autoHideDelay: 2000, icon:'none'},
      })
      return
    }
    let params = { code: b64 }
    this.$http.get('../api/shopee/getAccountInfo', {params}).then(res => {
      if(res.data.msg == 'success') {
        user.username = '統編'+res.data.data.cusNo + '  帳號'+res.data.data.accountId
        // user.username = `本次可開立的發票(${res.data.data.quotaInvoiceQty})張 ` + '統編'+res.data.data.cusNo + '  帳號'+res.data.data.accountId
        user.accountId = res.data.data.accountId
        user.cusNo = res.data.data.cusNo
        user.role = ''
        this.shopeeToken = res.data.data.shopeeToken

        this.user.cusNo = user.cusNo
        this.user.userId = user.accountId
        this.q.qCusNo = user.cusNo
        this.quotaInvoiceQty = res.data.data.quotaInvoiceQty
        let session = {
          shopeeToken: this.shopeeToken,
          cusNo: user.cusNo,
          accountId: user.accountId,
          cusNo: user.cusNo,
          paperPrint: this.paperPrint,
          quotaInvoiceQty: this.quotaInvoiceQty
        }

        sessionStorage.setItem('invGoToken', b64)
        sessionStorage.setItem('se', JSON.stringify(session))

        this.getLastUploadTitle()
      } else {
        this.$toast({ component: ToastificationContent,
              props: {title: '驗證錯誤-' + res.data.data, variant: 'danger', autoHideDelay: 2000, icon:'none'},
            })
      }
    }).catch(err => {
      console.log(err.code)
      console.log(err)
    })

    store.commit('commonParam/updateUser', user)
  },
  methods: {
    clickIssueInv() {
      if(this.user.userId == '未授權') {
        return
      }
      let params = {rowId : this.selectRowSpOrder.rowId, username:this.user.userId, shopeeToken: this.shopeeToken }
      this.$http.post('../api/shopee/issueInvoice', params).then(res => {
        if(res.data.msg == 'success') {
          this.queryInv(null)
          this.$toast({ component: ToastificationContent,
                props: {title: '開立完成(號碼'+res.data.data+')', variant: 'success', autoHideDelay: 2000, icon:'none'},
              })        
        } else {
          this.$toast({ component: ToastificationContent,
                props: {title: '開立失敗' + res.data.data, variant: 'danger', autoHideDelay: 2000, icon:'none'},
              })        
        }

      })
    },
    parseDate(d){
      if(d!=null && d.length >= 12){
        return d.substring(4,6) + '/' +d.substring(6,8) + ' ' +d.substring(8,10) + ':'+d.substring(10,12) 
      }
      return ''
    },
    queryInvDetail (row) {
      this.selectRowSpOrder = row
      let params = {parentId : row.rowId, shopeeToken: this.shopeeToken}
      this.$http.get('../api/shopee/queryInvDetail', {params}).then(res => {
        let seq = 1
        for(let i in res.data.data) {
          let d = res.data.data[i]
          res.data.data[i].seq = seq
          if(d.prodName.length > 10) {
            res.data.data[i].amt = d.qty*d.price
            res.data.data[i].prodName = d.prodName.substring(0,10) + '...'
          }
          seq++
        }
        this.invDetails = res.data.data
      })
    },
    queryInv (page) {
      if(page != null) {
        this.currentPage = page
      }
      let params = this.q
      params.page = this.currentPage
      params.pageSize = this.pageSize
      params.shopeeToken = this.shopeeToken

      this.$http.get('../api/shopee/queryInv', {params}).then(res => {
        this.orderTableData = []
        res.data.data.data.forEach(e => {
          e.editId = e.rowId
          e.orderDate = { create: this.parseDate(e.orderCreateDate), finish: this.parseDate(e.orderFinishDate) }
          e.uploadDate = this.parseDate(e.uploadDate)
          if(e.invNo == '完成') {
            e.invNo = '開立中'
          }
          e.invCreateDate = this.parseDate(e.invCreateDate)
          this.orderTableData.push(e)
        })
        this.rows = res.data.data.totalRow
      })
    },
    pageClick(e,page){
      this.queryInv(page)
    },
    getFormatDate(str ,type){
      if(type == 'mm/dd hh:mm') {
        if( str!= null) {
          return str.substring(4,6)+'/'+str.substring(6,8)+' '+ str.substring(8,10)+':'+str.substring(10,12)
        }
      } else if(type == 'yyyy/mm/dd hh:mm') {
        if(str!= null) {
          return str.substring(0,4)+'/'+str.substring(4,6)+'/'+str.substring(6,8)+' '+ str.substring(8,10)+':'+str.substring(10,12)
        }
      }
    },
    getLastUploadTitle(){
      let params = {
        shopeeToken: this.shopeeToken,
      }
      this.$http.get('../api/shopee/getTitleInfo', { params }).then(res => {
        res.data.data.forEach(e => {
          e.uploadDateDisplay = this.getFormatDate(e.uploadDate,'yyyy/mm/dd hh:mm')
          e.finishIssueDateDisplay = this.getFormatDate(e.finishIssueDate,'yyyy/mm/dd hh:mm')
          if(e.issueInvFlag == 'N' ) {
            e.finishIssueDateDisplay = '開立中'
            e.invQty = '開立中'
          }
        })
        this.xlsHistory = res.data.data
      })
    },
    downloadHistory (rowId,orgFileName) {
      axios({
          url: '../api/shopee/shopeeFile/' + rowId, //your url
          method: 'GET',
          responseType: 'blob', // important
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', orgFileName); 
          document.body.appendChild(link);
          link.click();
      });
    },
    startLogForDev(){
      let params = {fileName: this.jsonFileName}
      this.$http.get('../api/shopee/startLogForDev', { params }).then(res => {
        res.data.data.forEach(e => {
          e.uploadDateDisplay = this.getFormatDate(e.uploadDate,'mm/dd hh:mm')
          
        })
        this.xlsHistory = res.data.data
      })
    },
    uploadShOrderXlsx(){
      // if(true){ return }
      if(this.excelPwd == ''){
        this.$bvToast.toast('錯誤', {
          title: `請輸入蝦皮開啟excel密碼`,
          toaster: 'b-toaster-top-center' ,
          autoHideDelay: 1000,
          variant: 'danger',
          solid: false,
          appendToast: false,
        })
        return
      }
      if(this.fileSpOrder == null) {
        this.$bvToast.toast('錯誤', {
          title: `請選擇要上傳的檔案`,
          toaster: 'b-toaster-top-center' ,
          autoHideDelay: 1000,
          variant: 'danger',
          solid: false,
          appendToast: false,
        })
        return
      }
      
      let headers = {
        'Accpet': 'application/json',
        'content-type' : 'multipart/form-data',
        Authorization: this.token,
      }
      let user = store.state.commonParam.user
      this.formData = new FormData()
      this.formData.append('fileSpOrder', this.fileSpOrder)
      this.formData.append('shopeeToken', this.shopeeToken)
      this.formData.append('paperPrint', this.paperPrint)
      this.formData.append('pwd', this.excelPwd)
      
      this.show = true
      this.$http.post('../api/shopee/uploadShOrderXlsx', this.formData, {headers}).then(res => {
        this.show = false
        if(res.data.msg == 'success') {
          this.fileSpOrder  = null
          this.tab3UploadPrice = res.data.data.price
          this.tab3UploadTax= res.data.data.tax
          this.tab3UploadCreate = res.data.data.create
          this.tab3UploadExistOrderQty = res.data.data.existOrderQty
          this.getLastUploadTitle()
          this.$toast({ component: ToastificationContent,
                props: {title: '上傳完成', variant: 'success', autoHideDelay: 2000, icon:'none'},
              })
        } else {
          this.$toast({ component: ToastificationContent,
              props: {title: res.data.data, variant: 'danger', autoHideDelay: 10000, icon:'none'},
            })        
        }
 
      }).catch(() =>{
        this.show = false
        this.$bvToast.toast('錯誤', {
          title: `上傳錯誤`,
          toaster: 'b-toaster-top-center' ,
          autoHideDelay: 5000,
          variant: 'danger',
          solid: false,
          appendToast: false,
        })
      })
    },
    
    showCreateDialog(model) {
      this.$bvModal.show('modal-update')
    },
    showDeleteDialog(model) {
      this.$bvModal.show('modal-delete')
    },
    showUpdateDialog() {

      this.$bvModal.show('modal-update')
    },
    confirmUpdate(p) {
      p.preventDefault()
      this.toast('b-toaster-top-center', false)
      const rs = true
      if (rs) {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-update')
        })
      }
    },
    confirmDelete () {
      // p.preventDefault()
      this.toast('b-toaster-top-center', false)
      const rs = true
      if (rs) {
        this.$nextTick(() => {
          this.$bvModal.hide('modal-delete')
        })
      }
    },
    toast(toaster, append = false) {
      this.counter += 1
      this.$bvToast.toast('更新成功', {
        title: `Toaster ${toaster}`,
        toaster,
        variant: 'success',
        solid: false,
        appendToast: append,
      })
    },
  },
  computed:{
    canIssueInv(){
      if(this.selectRowSpOrder != null && this.selectRowSpOrder.invNo== null) {
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss" >
@import '~@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@/assets/scss/style.scss';

.history-table{
  border: 1px solid white;
  border-collapse: collapse;
}
// .dark-layout label {
//     font-size: 16px;
// }
</style>
